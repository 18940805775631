export enum InputType {
  NUMBER_INTEGER_POS = 'NUMBER_INTEGER_POS',
  NUMBER_FLOAT_POS = 'NUMBER_FLOAT_POS',
  ALPHABET = 'ALPHABET',
  NUMBER_ALPHABET = 'NUMBER_ALPHABET',
  SPECIAL = 'SPECIAL',
  ALL_TEXT = 'ALL_TEXT',
  IMG = 'IMG',
  SELECT = 'SELECT',
  SEARCH = 'SEARCH',
  C_SEARCH = 'C_SEARCH',
  DATE = 'DATE',
  TOOLTIP = 'TOOLTIP',
  SWITCH = 'SWITCH',
}

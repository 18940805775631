import { Button, Col, Popover, Row, Select, Spin } from 'antd'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'usehooks-ts'
import refreshActiveIcon from '../../../../assets/icons/refresh-active.svg'
import refreshDisableIcon from '../../../../assets/icons/refresh-disable.svg'
import CDatePicker from '../../../../components/Common/CDatePicker'
import CSwitch from '../../../../components/Common/CSwitch/CSwitch'
import { OptionItem } from '../../../../components/Common/SelectList/SelectList'
import { InputType } from '../../../../enum/InputType'
import {
  isOnlyContainsAlphabet,
  isOnlyContainsNumber,
  isOnlyContainsNumberAndAlphabet,
  isOnlyNumberFloat,
} from '../../../../utils'
import { DataTableParam, LineParam, OptionType } from './model'
import './wrapper.scss'

export interface CProps {
  children?: ReactNode
  isHeader?: boolean
  className?: string
}

export interface CInputProps {
  /**
   * trigger data when data is change
   */
  onChange?(data: NameAndValue): void

  /**
   * placeholder of input
   */
  placeHolder?: string
  /**
   * default of input
   */
  defaultValue?: any
  /**
   * value of input
   */
  value?: any
  /**
   * all validates of input
   */
  validates?: Array<Validate>
  /**
   * to style for input
   */
  className?: string
  /**
   * name of input
   */
  name?: string
  /**
   * indicate input is required or not
   */
  required?: boolean
  /**
   * required message when required is true
   */
  requiredMessage?: string
  /**
   * type of input
   */
  type?: InputType[]
  //disabled?: boolean,

  /**
   * max length of input
   */
  maxLength?: number
  /**
   * min value of input
   */
  min?: number
  /**
   * options of input when input type is select lis
   */
  options?: OptionItem[]
  /**
   * default selected value of input when input type is select lis
   */
  defaultSelectedValue?: string
  /**
   * format of input
   */
  format?: (value: string) => string
  /**
   * indicate only format on blur
   */
  formatOnBlur?: boolean
  /**
   * trigger when data change
   */
  onCheckValid?: (invalidProperty: InvalidProperty | null) => void
  /**
   * error message when manual force error
   */
  errorMessageForce?: string
  resetFlag?: boolean
  //qa
  removeTones?: boolean
  disabled?: boolean
  functionSearch?: (data: any) => Promise<any>
  onSearch?: (keyword: string) => Promise<OptionType[]>
  allowSelectMultiple?: boolean
  warningMessage?: string
  forceReset?: boolean
  requiredVariant?: boolean
  openConfirm?: boolean
  confirmMode?: boolean
  valueAfterNotConfirm?: string
  messageConfirm?: string
  onConfirm?: (name: string) => void
  onNotConfirm?: (name: string) => void
  onFocus?: (value: string) => string
  width?: number | string
  bold?: boolean
  invisible?: boolean
  invisibleIfChild?: boolean
  cellClass?: string
  element?: (props: any) => ReactNode
  textStyleFollowValue?: (
    value: string,
    line?: LineParam[],
    data?: DataTableParam[][]
  ) => string
  line?: LineParam[]
  refreshAction?: boolean
  allowRefresh?: boolean
  onRefresh?: (nameAndValue: NameAndValue) => void
  mustRefresh?: boolean
  mustRemove?: boolean
  forceInvisible?: boolean
  id?: string
  nextId?: string
  previousId?: string
}

export interface Validate {
  name?: string
  realtime?: boolean

  validateFunction(value: string, line?: LineParam[]): boolean

  message: string
  order: number
}

export interface InvalidProperty {
  validate: Validate
  name?: string
  value?: string
  isForce?: boolean
}

export interface NameAndValue {
  name?: string
  value?: string
  required?: boolean
  extraValue?: string
  extraValues?: []
}

export interface ResetProperty {
  name: string
  value: boolean
}

export const CTable = (props: CProps) => {
  return (
    <div style={{}} className={`wrapper-table table ${props?.className}`}>
      {props?.children}
    </div>
  )
}

export const CTr = (props: CProps) => {
  return (
    <div
      className={`wrapper-table table-row ${props?.className} ${
        props?.isHeader ? 'header-table py-5' : ''
      }`}
    >
      {props?.children}
    </div>
  )
}

export const CCell = (props: CProps) => {
  return (
    <div className={clsx('wrapper-table table-cell px-2.5', props?.className)}>
      {props?.children}
    </div>
  )
}

const sortValidates = (validates: Validate[]): Validate[] => {
  return validates?.sort((v1, v2) => v2.order - v1.order) || []
}
const addRequireToHeadOfValidates = (
  validates: Validate[],
  requiredMessage: string
): Validate[] => {
  let requiredValidate: Validate = {
    validateFunction: (value): boolean => {
      if (value?.trim().length > 0) return true
      return false
    },
    realtime: true,
    message: requiredMessage || '',
    order: -1,
  }
  validates.unshift(requiredValidate)
  return validates
}

/**
 *
 * The @CInput is the common input in this app
 * @author [chiendd]
 * @version 0.1
 */
export const CInput = (props: CInputProps) => {
  const {
    type = [InputType.ALL_TEXT],
    formatOnBlur = false,
    confirmMode = false,
    refreshAction = false,
    allowRefresh = false,
    mustRefresh = false,
    mustRemove = false,
    id,
    nextId,
    previousId,
    //format=(value)=> value
  } = props
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [isTyped, setIsTyped] = useState<boolean>(false)
  const [value, setValue] = useState<string>(
    props?.value || props?.defaultValue || undefined
  )
  const [currentError, setCurrentError] = useState<InvalidProperty | null>(null)
  const [options, setOptions] = useState<OptionType[]>([])
  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const debounceOnSearchKeyword = useDebounce(searchKeyword, 800)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const { t } = useTranslation()

  const onChangeValue = (e: any) => {
    let {
      name,
      value,
      extraValue = '',
      fromNotConfirm = false,
    } = e?.target || e
    let typeOfValue: InputType = InputType.ALL_TEXT
    if (isOnlyContainsAlphabet(value)) typeOfValue = InputType.ALPHABET
    else if (isOnlyContainsNumber(value))
      typeOfValue = InputType.NUMBER_INTEGER_POS
    else if (isOnlyNumberFloat(value)) typeOfValue = InputType.NUMBER_FLOAT_POS
    else if (isOnlyContainsNumberAndAlphabet(value))
      typeOfValue = InputType.NUMBER_ALPHABET
    const maxLength = props?.maxLength || Number.POSITIVE_INFINITY
    if (value?.length > maxLength) return
    if (
      type?.includes(InputType.ALL_TEXT) ||
      type?.includes(InputType.SELECT) ||
      type?.includes(InputType.C_SEARCH) ||
      type?.includes(InputType.DATE) ||
      type?.includes(typeOfValue) ||
      !value
    ) {
      if (type?.includes(InputType.C_SEARCH)) {
        setSearchKeyword('')
      }
      if (!formatOnBlur && props?.format) {
        value = props?.format(value)
      }
      if (props?.onChange) {
        if (fromNotConfirm) console.log({ value })

        props.onChange({ name, value, extraValue })
      }

      setValue(value)
    }

    // if(props?.removeTones) setValue(removeTones(value));
    // else setValue(value);
  }

  const onChangeSwitch = (value: boolean) => {
    const valueString = value ? '1' : '0'
    if (props?.onChange)
      props.onChange({ name: props?.name, value: valueString })
    setValue(valueString)
  }

  const onBlur = () => {
    if (confirmMode) setOpenConfirm(true)
    //setIsTyping(false);

    if (props?.required && !(value?.trim().length > 0)) {
      let requiredValidate: Validate = {
        validateFunction: (value): boolean => {
          if (value.trim().length > 0) return true
          return false
        },
        realtime: true,
        message: props?.requiredMessage || '',
        order: -1,
      }

      setCurrentError({
        name: props?.name || '',
        value,
        validate: requiredValidate,
      })
    } else {
      if (formatOnBlur && props?.format) {
        setValue(props?.format(value))
      }
    }
  }

  const doValidate = (validates: Validate[]): void => {
    let i = 0
    for (i = 0; i < validates.length; i++) {
      const validate = validates[i]
      if (
        validate?.validateFunction &&
        !validate?.validateFunction(value, props?.line)
      ) {
        console.log(validate)
        setCurrentError({ name: props?.name, value, validate })
        break
      }
    }
    if (i === validates.length) {
      setCurrentError(null)
    }
  }
  const reset = () => {
    setIsFocused(false)
    setIsTyped(false)
    setValue(props?.value || props?.defaultValue || '')
    setCurrentError(null)
    setOpenConfirm(false)
    setOptions([])
  }

  const validateWithConditions = (validates: Validate[]) => {
    if (!props?.required && value?.length === 0) setCurrentError(null)
    if (!props?.required && value?.length > 0) doValidate(validates)
    if (props?.required) doValidate(validates)
  }

  useEffect(() => {
    if (props?.format) {
      setValue(props.format(props.defaultValue || props.value))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.defaultValue, props?.value, props?.format])

  useEffect(() => {
    // if not yet focused or typed then do nothing
    if (!value && (!isFocused || !isTyped)) {
      setCurrentError(null)
      return
    }
    // sort validate list by order property
    let newValidates = sortValidates(props?.validates || [])
    // if require then add require validate to the head of validate list
    if (props?.required)
      newValidates = addRequireToHeadOfValidates(
        newValidates,
        props?.requiredMessage || ''
      )
    // do validate
    validateWithConditions(newValidates)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isFocused, props?.required, props?.value, props?.validates])

  useEffect(() => {
    if (!props?.onCheckValid) return
    props?.onCheckValid(currentError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentError])

  useEffect(() => {
    async function search() {
      if (!props?.onSearch || !searchKeyword.trim()) return []
      try {
        setIsFetching(true)
        const optionsResult = await props.onSearch(searchKeyword)
        setIsFetching(false)
        setOptions(optionsResult)
      } catch (error) {
        setOptions([])
        setIsFetching(false)
      }
    }

    search()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceOnSearchKeyword])

  useEffect(() => {
    setValue(props?.value)
  }, [props?.value])

  useEffect(() => {
    reset()
    return () => reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.resetFlag])

  useEffect(() => {
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.forceReset])

  useEffect(() => {
    const bodyOfModal = document.getElementsByClassName('ant-modal-body')
    const footerOfModal = document.getElementsByClassName('ant-modal-footer')
    if (bodyOfModal.length === 0 || footerOfModal.length === 0) return
    const modal = bodyOfModal[0]
    const footer = footerOfModal[0]

    if (openConfirm) {
      modal.classList.add('disable-all-event')
      footer.classList.add('disable-all-event')
    } else {
      modal.classList.remove('disable-all-event')
      footer.classList.remove('disable-all-event')
    }
  }, [openConfirm])

  // useEffect(() => {
  //   setOpenConfirm(confirmMode)
  // }, [confirmMode])

  useEffect(() => {
    setOpenConfirm(confirmMode)
  }, [confirmMode])

  useEffect(() => {
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onTyped = useCallback(() => {
    setIsTyped(true)
  }, [])

  const onFocused = useCallback(() => {
    if (value) {
      if (props?.onFocus) setValue(props.onFocus(value))
    }
    setOpenConfirm(false)
    setIsFocused(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const onConfirm = () => {
    setOpenConfirm(false)

    if (props?.onConfirm) {
      props.onConfirm(props?.name || '')
    }
  }
  const onNotConfirm = () => {
    setOpenConfirm(false)
    setValue(props?.valueAfterNotConfirm || '')
    onChangeValue({
      name: props?.name,
      value: props?.valueAfterNotConfirm || '',
      fromNotConfirm: true,
    })

    if (props?.onNotConfirm) props.onNotConfirm(props?.name || '')
  }

  const onRefresh = () => {
    const nameAndValue: NameAndValue = {
      name: props.name,
      value: props.value,
    }
    if (props?.onRefresh) props?.onRefresh(nameAndValue)
  }

  const dateFormat = t('date-format')
  const contentConfirm = (
    <div>
      <p className="text-xs">{props?.messageConfirm}</p>
      <Row gutter={10}>
        <Col>
          <Button onClick={onConfirm} className="oui-btn">
            Oui
          </Button>
        </Col>

        <Col>
          <Button onClick={onNotConfirm} className="non-btn">
            Non
          </Button>
        </Col>
      </Row>
    </div>
  )

  let textStyle = ''
  if (props.textStyleFollowValue)
    textStyle = props?.textStyleFollowValue(value, props.line)

  if (props?.type?.includes(InputType.DATE)) {
    return (
      <div className="wrapper-input" id={`wrapper-${id}`}>
        {confirmMode && openConfirm && (
          <Popover
            content={contentConfirm}
            trigger="click"
            open={openConfirm}
          ></Popover>
        )}

        <CDatePicker
          tabIndex={0}
          id={id}
          aria-roledescription={nextId}
          aria-keyshortcuts={previousId}
          role={previousId}
          disabled={props?.disabled}
          // format={ddMMyyFormat}
          value={value ? dayjs(value, dateFormat) : undefined}
          onChange={(date, dateString) => {
            onChangeValue({ name: props.name, value: dateString })
          }}
          style={{
            width: props?.width || 200,
            borderColor: props.warningMessage ? '#FFA500' : '',
          }}
          // placeholder={props.placeHolder}
          className={` ${
            currentError?.validate?.realtime || props?.errorMessageForce
              ? 'invalid-input'
              : ''
          } ${props?.className} ol-input ${
            props?.disabled ? 'input-disabled' : ''
          }
          ${textStyle}
          `}
          onBlur={onBlur}
          onSelect={onTyped}
          onFocus={onFocused}
        />
        {props?.errorMessageForce ? (
          <div className="message text-left break-words min-h-fit">
            <p className="p-0 m-0 break-words">{props?.errorMessageForce}</p>
          </div>
        ) : null}
      </div>
    )
  }

  if (props?.type?.includes(InputType.SELECT)) {
    return (
      <div className="wrapper-input w-fit h-full" id={`wrapper-${id}`}>
        {confirmMode && (
          <Popover
            content={contentConfirm}
            trigger="click"
            open={openConfirm}
          ></Popover>
        )}
        <Select
          tabIndex={0}
          id={id}
          aria-roledescription={nextId}
          aria-keyshortcuts={previousId}
          maxLength={props?.maxLength || Number.POSITIVE_INFINITY}
          // defaultValue={props.value || props?.defaultSelectedValue}
          style={{ width: props?.width || 140, padding: 0 }}
          onChange={(value) => {
            onChangeValue({ name: props.name, value })
          }}
          options={props?.options}
          value={value || undefined}
          disabled={props.disabled}
          onBlur={onBlur}
          onSelect={onTyped}
          onFocus={onFocused}
          className={` ${
            currentError?.validate?.realtime || props?.errorMessageForce
              ? 'invalid-input'
              : ''
          } ${props?.className} ol-input ${
            props?.disabled ? 'input-disabled' : ''
          }`}
          placeholder={props?.placeHolder}
          bordered={false}
          notFoundContent={t('notFoundResult')}
        />
        <div className="message text-left break-words min-h-fit !text-[7px]">
          <p className="p-0 m-0 break-words">{props?.errorMessageForce}</p>
        </div>
      </div>
    )
  }

  if (props?.type?.includes(InputType.C_SEARCH)) {
    return (
      <div className="wrapper-input" id={`wrapper-${id}`}>
        {confirmMode && openConfirm && (
          <Popover
            content={contentConfirm}
            trigger="click"
            open={openConfirm}
          ></Popover>
        )}
        <Select
          tabIndex={0}
          id={id}
          aria-roledescription={nextId}
          aria-keyshortcuts={previousId}
          maxLength={props?.maxLength || Number.POSITIVE_INFINITY}
          // defaultValue={props.value || props?.defaultSelectedValue}
          value={value || undefined}
          showSearch
          labelInValue
          filterOption={false}
          onSearch={async (value) => {
            setSearchKeyword(value)
          }}
          options={options}
          placeholder={props.placeHolder}
          style={{ width: props?.width || '100%', padding: 0 }}
          disabled={props.disabled}
          onChange={(value, option) => {
            let optionCast = option as OptionType
            const nameAndValue = {
              name: props.name,
              value: optionCast.value,
              extraValue: optionCast?.extraValue || '',
            }
            onChangeValue(nameAndValue)
          }}
          onBlur={onBlur}
          onSelect={onTyped}
          onFocus={onFocused}
          className={` ${
            currentError?.validate?.realtime || props?.errorMessageForce
              ? 'invalid-input'
              : ''
          } ${props?.className} ol-input ${
            props?.disabled ? 'input-disabled' : ''
          }`}
          bordered={false}
          notFoundContent={
            isFetching ? (
              <Spin size="small" />
            ) : (
              <div className="text-center">{t('notFoundResult')}</div>
            )
          }
          autoClearSearchValue={true}
        />
        <div className="message text-left break-words min-h-fit">
          <p className="p-0 m-0 break-words">
            {(currentError?.validate?.realtime
              ? currentError?.validate?.message
              : '') || props?.errorMessageForce}
          </p>
        </div>
      </div>
    )
  }

  if (props.type?.includes(InputType.SWITCH)) {
    return (
      <div className="wrapper-input">
        <CSwitch
          id={id}
          nextId={nextId}
          previousId={previousId}
          disabled={props?.disabled}
          checked={value !== '0'}
          onChange={onChangeSwitch}
        />
      </div>
    )
  }

  return (
    <div className="wrapper-input">
      {confirmMode && openConfirm && (
        <Popover
          placement="top"
          content={contentConfirm}
          trigger="click"
          open={openConfirm}
        ></Popover>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <input
          id={id}
          data-next-id={nextId}
          data-previous-id={previousId}
          min={props?.min || Number.NEGATIVE_INFINITY}
          maxLength={props?.maxLength || Number.POSITIVE_INFINITY}
          disabled={props?.disabled || false}
          value={value}
          // defaultValue={props?.value || props?.defaultValue || ""}
          src={value}
          onBlur={onBlur}
          onInput={onTyped}
          onFocus={onFocused}
          className={`
         ${
           currentError?.validate?.realtime || props?.errorMessageForce
             ? 'invalid-input'
             : ''
         } ${props?.className} ol-input ${
            props?.disabled ? 'input-disabled' : ''
          }
          ${props?.bold ? 'font-bold' : ''}

          ${textStyle}
          `}
          onChange={onChangeValue}
          type={props?.type?.includes(InputType.IMG) ? 'image' : 'text'}
          name={props?.name}
          alt={props?.type?.includes(InputType.IMG) ? 'image' : 'text'}
          placeholder={props?.placeHolder}
          tabIndex={-1}
          style={{
            width: props.width,
            textDecoration: `${
              mustRefresh || mustRemove ? 'line-through' : ''
            }`,
          }}
        />
        <span style={{ marginLeft: 10, marginTop: 5, cursor: 'pointer' }}>
          {refreshAction && allowRefresh && (
            <img
              onClick={onRefresh}
              width={30}
              src={refreshActiveIcon}
              alt=""
            />
          )}
          {refreshAction && !allowRefresh && (
            <img width={30} src={refreshDisableIcon} alt="" />
          )}
        </span>
      </div>

      <div className="message text-left break-words min-h-fit">
        <p className="p-0 m-0 break-words">
          {(currentError?.validate?.realtime
            ? currentError?.validate?.message
            : '') || props?.errorMessageForce}
        </p>
      </div>
    </div>
  )
}
